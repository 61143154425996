






import { defineComponent } from "@vue/composition-api"

import { createDefaultUserData } from "@/_service/user"

import UserCreateUpdate from "@/view/User/component/UserCreateUpdate.vue"

export default defineComponent({
  name: "UserCreate",

  components: { UserCreateUpdate },

  setup() {
    const userData = createDefaultUserData()

    return {
      userData,
    }
  },
})
